
function init(parentSelector) {
  const nav = document.querySelector('#js-functions-overview-nav-overview')

  // disable on functions overview page
  if (nav !== null) {
    return false
  }

  const run = async () => {
    try {
      const [lottie ] = await Promise.all([
        import( /* @vite-ignore */'lottie-web')
      ])

      // console.log('lottie.js');
      let parentEl = document
      if (parentSelector !== null) {
        const el = document.querySelector(parentSelector)
        parentEl = el !== null ? el : document
      }

      const lotties = parentEl.querySelectorAll('lottie-player')
      lotties.forEach((el) => {
        const filePath = el.getAttribute('src')
        if (!filePath) {
          // console.log('no lottie files')
          return false
        }
        lottie.loadAnimation({
          container: el,
          renderer: 'svg',
          loop: true,
          // autoplay: el.dataset.autoplay === 'true' ? 1 : 0,
          autoplay: 0,
          path: filePath,
          name: el.dataset.name
        })

        lottie.setQuality('medium')
      })

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              let entryName = entry.target.dataset.name
              // let entryAutoplay = entry.target.dataset.autoplay;
              // let ratio = entry.intersectionRatio

              if (!entry.isIntersecting) {
                lottie.pause(entryName)
              } else {
                lottie.play(entryName)
              }
            })
          },
          { threshold: 0.5 }
      )

      lotties.forEach((image) => {
        observer.observe(image)
      })
    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}

export default init
