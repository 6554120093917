import lottie from './_partials/lottie'
import mediaAccordion from './_partials/functionDetail/mediaAccordion'
import animateFunctionSections from './_partials/functions/animateFunctionSections'

const init = () => {
  lottie()
  mediaAccordion()
  animateFunctionSections()
}

document.addEventListener('DOMContentLoaded', init, false)
document.addEventListener('htmx:afterRequest', init, false)


