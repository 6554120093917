export default () => {
  const accordionTerms = document.querySelectorAll('.js-media-accordion-term')
  if (accordionTerms.length < 1) {
    return false
  }

  const run = async () => {
    try {
      const [{gsap, ScrollTrigger}, toggleLottieVisuals] = await Promise.all([
        import( /* @vite-ignore */'../gsapImport'),
        import( /* @vite-ignore */'../toggleLottieVisuals')
      ])

      gsap.registerPlugin(ScrollTrigger)

      for (const term of accordionTerms) {
        term.addEventListener(
            'click',
            (e) => {
              let currTerm = e.currentTarget
              let targetVisual = document.getElementById(currTerm.dataset.mediaTarget)
              let termVisuals = document.querySelectorAll('.' + currTerm.dataset.mediaGroup)

              if (termVisuals.length < 1 || targetVisual === null) {
                return false
              }

              for (const visual of termVisuals) {
                if (visual !== targetVisual) {
                  visual.classList.remove('is-active')
                  toggleLottieVisuals.hideVisual(visual)
                }
              }

              targetVisual.classList.add('is-active')
              toggleLottieVisuals.showVisual(targetVisual)
            },
            false
        )
      }

      const media = window.matchMedia('screen and (min-width: 768px)')
      // const faqAnimationsLg = document.querySelectorAll('.js-faq-anim-lg')
      const faqAnimationsSm = document.querySelectorAll('.js-faq-anim-sm')

      const checkSTState = () => {
        if (media.matches) {
          const fstFaqAnim = document.querySelector('.js-faq-anim-lg.is-active')
          if (fstFaqAnim) {
            fstFaqAnim.dataset.autoplay = 'true'
          }
          for (const anim of faqAnimationsSm) {
            anim.dataset.autoplay = 'false'
          }
        } else {
          for (const anim of faqAnimationsSm) {
            anim.dataset.autoplay = 'true'
          }
        }
      }

      ScrollTrigger.addEventListener('refreshInit', checkSTState)
    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}
