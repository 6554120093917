export default () => {
  const nav = document.querySelector('#js-functions-overview-nav-overview')
  const list = document.querySelector('#js-functions-overview-list')

  // enable only on functions overview page
  if (nav === null) {
    return false
  }

  const run = async () => {
    try {
      const [{gsap, ScrollTrigger, ScrollToPlugin}, lottie ] = await Promise.all([
        import( /* @vite-ignore */'../gsapImport'),
        import( /* @vite-ignore */'lottie-web')
      ])

      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

      let allowAnim = 1
      let disableScroll = false
      // let disableScrollTimout

      const breakpoint = window.matchMedia('(min-width:1024px)')
      const sections = gsap.utils.toArray('#js-functions-overview-list .js-content-marker')
      // const functionsContainerTopOffset = document.querySelector('#js-functions-overview-list').getBoundingClientRect()
      //     .top

      const navLinks = document.querySelectorAll('#js-functions-overview-nav a, #functions-slide-switcher a')

      const lottieElements = document.querySelectorAll('lottie-player')

      const isInViewport = (elem) => {
        var bounding = elem.getBoundingClientRect()
        return bounding.top >= 0
      }

      const checkLottiesPosition = () => {
        lottieElements.forEach((el) => {
          if (isInViewport(el)) {
            // console.log('lottie', el.dataset.name, ' is visible')
            lottie.play(el.dataset.name)
          }
        })
      }

      lottieElements.forEach((el) => {
        const filePath = el.getAttribute('src')
        if (!filePath) {
          return false
        }
        lottie.loadAnimation({
          container: el,
          renderer: 'svg',
          loop: true,
          // autoplay: el.dataset.autoplay === 'true' ? 1 : 0,
          autoplay: 0,
          path: filePath,
          name: el.dataset.name,
          rendererSettings: {
            progressiveLoad: true
          }
        })
        lottie.setQuality('medium')
      })

      ScrollTrigger.addEventListener('scrollStart', () => {
        // console.log('scrollStart')
        lottie.pause()
      })

      ScrollTrigger.addEventListener('scrollEnd', () => {
        // console.log('scrollEnd')
        checkLottiesPosition()
      })

      const changeLink = (activeSectionId) => {
        navLinks.forEach((el) => {
          if (el.getAttribute('href') === activeSectionId) {
            el.classList.add('is-active')
          } else {
            el.classList.remove('is-active')
          }
        })
      }

      const goToSection = (sectionId) => {
        if (allowAnim && breakpoint.matches === true) {
          disableScroll = true

          gsap.to(window, {
            scrollTo: {
              y: sectionId,
              autoKill: false,
              ease: 'linear'
            },
            onStart: () => {
              allowAnim = false
            },
            onComplete: () => {
              changeLink(sectionId)
              const lottieElement = document.querySelector(sectionId + ' lottie-player')
              if (lottieElement !== null) {
                // setTimeout(() => {
                // lottie.play(lottieElement.dataset.name)
                // }, 500)
              }
              allowAnim = true

              setTimeout(() => {
                disableScroll = false
              }, 500)
            }
          })
        }
      }

      const checkScroll = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!disableScroll) {
          window.scrollBy(0, e.deltaY)
        }
      }

      window.addEventListener('wheel', checkScroll, {
        capture: true,
        passive: false
      })

      ScrollTrigger.create({
        trigger: list,
        start: 'top -1',
        end: 'bottom top-=50%',
        toggleClass: {className: 'is-active', targets: nav}
      })

      ScrollTrigger.create({
        trigger: '#integrations',
        start: 'top bottom-=25px',
        end: 'bottom top+=25px',
        onEnter: () => {
          changeLink('#integrations')
        },
        scrub: 1
      })

      navLinks.forEach((el) => {
        el.addEventListener(
            'click',
            (e) => {
              e.preventDefault()
              const targetId = e.target.getAttribute('href')
              // const targetAnim = document.querySelector(targetId + ' lottie-player')

              let offsetY = 120
              if (breakpoint.matches === true) {
                offsetY = 0
              }

              gsap.to(window, {
                scrollTo: {
                  y: targetId,
                  offsetY: offsetY,
                  autoKill: false,
                  ease: 'linear'
                },
                onStart: () => {
                  allowAnim = false
                },
                onComplete: () => {
                  allowAnim = true
                  changeLink(e.target.getAttribute('href'))
                }
              })
            },
            false
        )
      })

      const init = () => {
        ScrollTrigger.matchMedia({
          '(max-height: 960px) and (orientation: landscape)': () => {
            sections.forEach((section, i) => {
              ScrollTrigger.create({
                trigger: section,
                start: 'top bottom-=25px',
                end: 'bottom top+=25px',
                // markers: 'true',
                onEnter: () => {
                  if (i > 0) {
                    // disable for 1st slide
                    goToSection('#' + section.id)
                  }
                },
                onEnterBack: () => {
                  // lottie.pause()
                  goToSection('#' + section.id)
                },
                scrub: true
              })
            })
          },
          '(min-height: 861px) and (orientation: landscape)': () => {
            // console.log('run part of screen sections slider')
          }
        })
      }

      const breakpointChecker = () => {
        if (breakpoint.matches === true) {
          // console.log('desktop animation')
          return init()
        } else {
          // console.log('mobile animation')
        }
      }

      breakpoint.addListener(breakpointChecker)
      breakpointChecker()

    } catch (err) {
      /* eslint-disable */
      console.log(err.message)
      /* eslint-enable */
    }
  }

  run()
}
